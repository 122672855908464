import PageTitle from "../../common/PageTitle";
import PageLayout from "../../layout/PageLayout";

import card1Thumb from "../../../images/cards/card1-thumbnail.png";
import card1 from "../../../images/cards/card1.png";
import card2Thumb from "../../../images/cards/card2-thumbnail.png";
import card2 from "../../../images/cards/card2.png";
import card3Thumb from "../../../images/cards/card3-thumbnail.png";
import card3 from "../../../images/cards/card3.png";
import card4Thumb from "../../../images/cards/card4-thumbnail.png";
import card4 from "../../../images/cards/card4.png";

const cardList = [
	{
		id: "1",
		thumbnail: card1Thumb,
		imageUrl: card1,
	},
	{
		id: "2",
		thumbnail: card2Thumb,
		imageUrl: card2,
	},
	{
		id: "3",
		thumbnail: card3Thumb,
		imageUrl: card3,
	},
	{
		id: "4",
		thumbnail: card4Thumb,
		imageUrl: card4,
	},
];

export const Miscellaneous = () => {
	const content = (
		<div>
			<PageTitle title="Publication" />

			<p className="mb-8 text-center">
				Click on card below to download the file
			</p>

			<div className="flex flex-col gap-8 md:flex md:flex-row md:gap-4">
				{cardList.map((item) => (
					<div key={item.id} className="flex flex-col items-center">
						<a
							href={item.imageUrl}
							download={`card-${item.id}-downloaded.png`}
						>
							<img src={item.thumbnail} alt="thumbnail" />
						</a>
					</div>
				))}
			</div>
		</div>
	);

	return <PageLayout children={content} />;
};
