/**
 * Data of the members of the committee
 *
 */

export const members = [
	{
		id: 1,
		name: "Hangma Lawati",
		description: "Chairperson",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-1.jpg?alt=media&token=9c7bdef5-3870-45b3-84c9-2056314bbb9d",
	},
	{
		id: 33,
		name: "Deepa Yongya",
		description: "Vice Chairperson 1",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-6.jpg?alt=media&token=bc14f73b-140e-423f-9360-4a85aa3e39ee",
	},
	{
		id: 3,
		name: "Sandesh Nembang",
		description: "Vice Chairperson 2",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-7.jpg?alt=media&token=5e22eb2c-fc74-4a46-8cb3-836083e412d5",
	},
	{
		id: 25,
		name: "Kishan Yakso",
		description: "Vice Chairperson 3",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-kisan.png?alt=media&token=52a0a462-c018-4a84-954d-9caa25d77c33",
	},
	{
		id: 2,
		name: "Surendra Thebe",
		description: "Secretary",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-2.jpg?alt=media&token=1d02ce8c-65f2-4b91-9f37-bb68b7b2bb9c",
	},
	{
		id: 9,
		name: "Milan (Sultan) Edhingo",
		description: "Assistant Secretary",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-10.jpg?alt=media&token=ae1fdb8c-f987-4dc2-bcb1-b70dbbf4445a",
	},
	{
		id: 4,
		name: "Suva Nembang",
		description: "Treasurer",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-5.jpg?alt=media&token=08e7e143-c10f-46cb-9dfd-32f185db0fd6",
	},
	{
		id: 6,
		name: "Nirmal Warumhang",
		description: "Education and Publication Head",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-9.jpg?alt=media&token=1f4ca793-c947-4471-be8d-f90de109be81",
	},
	{
		id: 7,
		name: "Shikhar Sambahamphe",
		description: "Student and Youth Liaison Head",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-3.jpg?alt=media&token=db321ea9-04d2-4ef3-a893-1c418b0aed43",
	},
	{
		id: 33,
		name: "Naresh Tumbahangphe",
		description: "Media and Publicity Head",
	},
	{
		id: 5,
		name: "Mohan Saba",
		description: "Media and Publicity Assistant Head",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-4.jpg?alt=media&token=034e3bdb-e5d6-4d40-a5ba-2fe321086f79",
	},
	{
		id: 26,
		name: "Tek Mabohang",
		description: "Culture, Language and Script Head",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-tek.jpg?alt=media&token=1319677e-08ea-46f8-a989-9934f1905105",
	},

	{
		id: 10,
		name: "Achbin Sawenhim",
		description: "Member",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-8.jpg?alt=media&token=b40400d8-52ca-475d-a844-2cac30e95f40",
	},
	{
		id: 23,
		name: "Kwihang Begha",
		description: "Member",
		imageUrl: null,
	},
	{
		id: 38,
		name: "Denis Palungwa",
		description: "Member",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-denish.png?alt=media&token=25dd43f3-f6ed-4587-8b5a-d1619338cb15",
	},
	{
		id: 33,
		name: "Lochan Khimding",
		description: "Member",
		imageUrl: null,
	},
	{
		id: 21,
		name: "Sunam Labung",
		description: "Member",
		imageUrl: null,
	},
	{
		id: 22,
		name: "Seekum Chongbang",
		description: "Member",
		imageUrl: null,
	},
	{
		id: 24,
		name: "Reshmi Mangden",
		description: "Member",
		imageUrl: null,
	},
	{
		id: 31,
		name: "Dr. Dipen Hukpa Chongbang",
		description: "Member",
		imageUrl: null,
	},
	{
		id: 34,
		name: "Jivan Sherma",
		description: "Member",
		imageUrl: null,
	},
	{
		id: 35,
		name: "Anmol Khimding",
		description: "Member",
		imageUrl: null,
	},
	{
		id: 36,
		name: "Dr. Rashma Limbu",
		description: "Member",
		imageUrl: null,
	},
	{
		id: 37,
		name: "Rekha Limbu",
		description: "Member",
		imageUrl: null,
	},
	{
		id: 39,
		name: "Lalit Khoyahang",
		description: "Member",
		imageUrl: null,
	},
	{
		id: 54,
		name: "Khagendra Fago Chongbang",
		description: "Member",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/member-khagendra.png?alt=media&token=09dace96-3b5e-4eb7-a26c-3d9d078ef8d2",
	},
	{
		id: 55,
		name: "Pragya Labung",
		description: "Member",
		imageUrl: null,
	},
	{
		id: 57,
		name: "Yogendra Tawa",
		description: "Member",
		imageUrl: null,
	},
];

export const advisors = [
	{
		id: 1001,
		name: "Lahang Chemjong",
		description: "Founder Co-ordinator, YWC UK",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/lahang-member.png?alt=media&token=025e58dd-ad1d-4631-93ed-e74cac96f892",
	},
	{
		id: 1002,
		name: "Narbir Angbo",
		description: "Former Chairperson, KYC UK",
		imageUrl:
			"https://firebasestorage.googleapis.com/v0/b/yakthung-wensa-chumbho-uk.appspot.com/o/narbir-angbo.png?alt=media&token=b52f5b78-44c8-4453-8a7e-45872ef836c2",
	},
];
