import React from "react";
import { Link } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { AiFillTikTok } from "react-icons/ai";
import { FaArrowRight } from "react-icons/fa";

const MenuModal = ({ isOpen, closeModal }) => {
	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center z-50 px-4">
			<div className="bg-white rounded-lg w-full max-w-lg p-6 relative flex flex-col mb-6">
				<div className="absolute top-0 right-0 p-2">
					<IoMdCloseCircle size={30} onClick={closeModal} />
				</div>
				<Link
					to="/"
					className="mr-5 hover:text-red-400 text-2xl font-bold text-left py-2"
				>
					Home
				</Link>
				<Link
					to="/calendar"
					className="mr-5 hover:text-red-400 text-2xl font-bold text-left py-2"
				>
					Calendar
				</Link>
				<div className="mb-2 flex flex-col">
					<Link
						to="/festivals"
						className="mr-5 hover:text-red-400 text-2xl font-bold text-left py-2 pl-2"
					>
						<FaArrowRight size={20} className="inline" />
						<span className="ml-2">Festivals</span>
					</Link>
					<Link
						to="/events	"
						className="mr-5 hover:text-red-400 text-2xl font-bold text-left py-2 pl-2"
					>
						<FaArrowRight size={20} className="inline" />
						<span className="ml-2">Events</span>
					</Link>
				</div>
				<Link
					to="/vision"
					className="mr-5 hover:text-red-400 text-2xl font-bold text-left py-2"
				>
					Vision
				</Link>
				<div className="mb-2">
					<Link
						to="/team"
						className="mr-5 hover:text-red-400 text-2xl font-bold text-left py-2 pl-2"
					>
						<FaArrowRight size={20} className="inline" />
						<span className="ml-2">Team</span>
					</Link>
				</div>
				<Link
					to="/publications/miscellaneous"
					className="mr-5 hover:text-red-400 text-2xl font-bold text-left py-2"
				>
					Publications
				</Link>
				<div className="mb-2">
					<Link
						to="/publications/miscellaneous"
						className="mr-5 hover:text-red-400 text-2xl font-bold text-left py-2 pl-2"
					>
						<FaArrowRight size={20} className="inline" />
						<span className="ml-2">Miscellaneous</span>
					</Link>
				</div>
				<Link
					to="/contact-us"
					className="mr-5 hover:text-red-400 text-2xl font-bold text-left py-2"
				>
					Contact Us
				</Link>
			</div>
			<div className="bg-white rounded-lg w-full max-w-lg p-6 relative flex flex-col">
				<div className="inline-flex sm:ml-auto sm:mt-0 mt-0 justify-center sm:justify-start gap-10">
					<a
						href="https://www.facebook.com/profile.php?id=100082807702641"
						target="_blank"
						rel="noreferrer noopener"
						className="pt-1"
					>
						<FaFacebookSquare size={42} color="black" />
					</a>
					<a
						href="https://www.youtube.com/@YakthungWensaChumbhoUK"
						target="_blank"
						rel="noreferrer noopener"
					>
						<IoLogoYoutube size={47} color="black" />
					</a>
					<a
						href="https://www.tiktok.com/@yakthungwensachumbhouk"
						target="_blank"
						rel="noreferrer noopener"
						className="pt-[1px]"
					>
						<AiFillTikTok size={46} color="black" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default MenuModal;
